import { Button, TextField } from "@mui/material"
import { useState } from "react";
import { CustomerService } from "../../sojoApi";
import "./BrandConfig.css"
import useToken from "./Hooks/useToken";

export const BrandConfigLogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { setAccessToken } = useToken();

    const submitForm = () => {
        CustomerService.customerTokenCustomerCustomerTokenPost({
            email: email,
            password: password
        }).then((result) => {
            setAccessToken(result);
            window.location.reload();
        }).catch((err) => {
            alert("Wrong username or password");
        });;
    };

    return (
        <div className="login-form">
            <h1>The brand configuration service requires an admin login</h1>
            <TextField
                name="email"
                type="text"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
            />
            <br></br>
            <TextField
                name="password"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                />
            <br></br>
            <Button onClick={submitForm}>Log in</Button>
        </div>
    )
}