/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Events during the lifecycle of a service
 */
export enum ServiceEvent {
    SERVICE_STARTED = 'service_started',
    SERVICE_PAUSED = 'service_paused',
    QC_READY = 'qc_ready',
    QC_PASSED = 'qc_passed',
    QC_FAILED = 'qc_failed',
    REFUNDED = 'refunded',
}
