/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticationToken } from '../models/AuthenticationToken';
import type { Basket } from '../models/Basket';
import type { BasketItem } from '../models/BasketItem';
import type { BasketItemRequest } from '../models/BasketItemRequest';
import type { Body_customer_token_swagger_customer_customer_token_swagger_post } from '../models/Body_customer_token_swagger_customer_customer_token_swagger_post';
import type { CheckoutRequest } from '../models/CheckoutRequest';
import type { CheckoutResponseV2 } from '../models/CheckoutResponseV2';
import type { CustomerUpdateRequest } from '../models/CustomerUpdateRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { NewBasketItem } from '../models/NewBasketItem';
import type { OrderUpdate } from '../models/OrderUpdate';
import type { ResolvedCustomer } from '../models/ResolvedCustomer';
import type { ResolvedOrder } from '../models/ResolvedOrder';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerService {

    /**
     * Get Basket
     * Retrieve current basket for given customer
     * @param couponCode 
     * @returns Basket The customers current basket
     * @throws ApiError
     */
    public static getBasketCustomerBasketGet(
couponCode?: string,
): CancelablePromise<Basket> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer/basket',
            query: {
                'coupon_code': couponCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Basket
     * Update an item in a customer's basket
     * @param basketItemId 
     * @param requestBody 
     * @returns NewBasketItem The updated item from the customer's basket
     * @throws ApiError
     */
    public static putBasketCustomerBasketPut(
basketItemId: string,
requestBody: BasketItemRequest,
): CancelablePromise<NewBasketItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/customer/basket',
            query: {
                'basket_item_id': basketItemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Basket
     * Add a new item to a customer's basket
     * @param requestBody 
     * @returns BasketItem The basket item which was added to basket
     * @throws ApiError
     */
    public static postBasketCustomerBasketPost(
requestBody: BasketItemRequest,
): CancelablePromise<BasketItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/basket',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Basket
     * Remove an item from a customer's basket
     * @param id 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static deleteBasketCustomerBasketDelete(
id: string,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/customer/basket',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Clear Basket
     * Clear a customer's basket
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static clearBasketCustomerClearBasketDelete(): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/customer/clear_basket',
        });
    }

    /**
     * Post Checkout V2
     * Create a stripe checkout session, or return order info if the order was free
     * @param requestBody 
     * @returns CheckoutResponseV2 Info needed for Stripe checkout, or for generating a confirmation page if the order was free
     * @throws ApiError
     */
    public static postCheckoutV2CustomerCheckoutV2Post(
requestBody: CheckoutRequest,
): CancelablePromise<CheckoutResponseV2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/checkout_v2',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Details
     * Retrieve the details of a given customer
     * @returns ResolvedCustomer The customer's details
     * @throws ApiError
     */
    public static getCustomerDetailsCustomerCustomerDetailsGet(): CancelablePromise<ResolvedCustomer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer/customer_details',
        });
    }

    /**
     * Put Customer Details
     * Update an existing customer
     * @param requestBody 
     * @returns ResolvedCustomer Updated customer
     * @throws ApiError
     */
    public static putCustomerDetailsCustomerCustomerDetailsPut(
requestBody: CustomerUpdateRequest,
): CancelablePromise<ResolvedCustomer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/customer/customer_details',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Account
     * Send a request to delete an account, to be actioned within 30 days
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static deleteAccountCustomerDeleteAccountPost(): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/delete_account',
        });
    }

    /**
     * Delete Account V2
     * Send a request to delete an account, to be actioned within 30 days
     * @param reason 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static deleteAccountV2CustomerDeleteAccountV2Post(
reason?: string,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/delete_account_v2',
            query: {
                'reason': reason,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Customer Token
     * Get OAuth2 token for customer login
     * @param requestBody 
     * @returns AuthenticationToken OAuth2 token
     * @throws ApiError
     */
    public static customerTokenCustomerCustomerTokenPost(
requestBody: LoginRequest,
): CancelablePromise<AuthenticationToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/customer_token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Customer Token Swagger
     * Get OAuth2 token for customer login
     * @param formData 
     * @returns AuthenticationToken OAuth2 token for SwaggerUI
     * @throws ApiError
     */
    public static customerTokenSwaggerCustomerCustomerTokenSwaggerPost(
formData: Body_customer_token_swagger_customer_customer_token_swagger_post,
): CancelablePromise<AuthenticationToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/customer_token_swagger',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Customer Token
     * Get OAuth2 token for customer login from (long lived) refresh token
     * @returns AuthenticationToken OAuth2 token without refresh token
     * @throws ApiError
     */
    public static refreshCustomerTokenCustomerRefreshCustomerTokenPost(): CancelablePromise<AuthenticationToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer/refresh_customer_token',
        });
    }

    /**
     * Get Order
     * Retrieve an order with a specific ID
     * @param orderId 
     * @returns ResolvedOrder The requested order
     * @throws ApiError
     */
    public static getOrderCustomerOrderGet(
orderId: string,
): CancelablePromise<ResolvedOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer/order',
            query: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Order
     * Update an order's pickup or dropoff information
     * @param requestBody 
     * @param asOf 
     * @returns ResolvedOrder Updated order
     * @throws ApiError
     */
    public static putOrderCustomerOrderPut(
requestBody: OrderUpdate,
asOf?: string,
): CancelablePromise<ResolvedOrder> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/customer/order',
            query: {
                'as_of': asOf,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Orders
     * Retrieve all orders for a given customer
     * @returns ResolvedOrder List of all of the customer's orders
     * @throws ApiError
     */
    public static getOrdersCustomerOrdersGet(): CancelablePromise<Array<ResolvedOrder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customer/orders',
        });
    }

}
