import { Outlet } from "react-router-dom";
import { OpenAPI } from "../../../sojoApi";
import { BrandConfigLogin } from "../BrandConfigLogin";
import useToken from "../Hooks/useToken";

export const BrandConfigOutlet = () => {
    const { accessToken } = useToken();

    if (accessToken !== undefined) {
        OpenAPI.TOKEN = accessToken;
    } else {
        OpenAPI.TOKEN = undefined;
    }

    return (
        <>
            {
                accessToken === undefined
                    ? <BrandConfigLogin />
                    : <Outlet />
            }
        </>
    );
}