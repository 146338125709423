/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FittingChoice {
    MATCH = 'match',
    PIN = 'pin',
    MEASURE = 'measure',
    PIN_IN_STORE = 'pin_in_store',
}
