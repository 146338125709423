/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining coupon type
 */
export enum CouponType {
    PERCENTAGE = 'percentage',
    FIXED = 'fixed',
}
