/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Display type for an item level question
 */
export enum QuestionDisplayType {
    BUTTONS = 'buttons',
    DROPDOWN = 'dropdown',
}
