import "./BrandConfig.css";
import { FileUploader } from "react-drag-drop-files";
import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService, Store } from "../../sojoApi";
import { BrandConfigPath } from "./BrandConfigPath";
import Select, { SingleValue } from "react-select";
import useToken from "./Hooks/useToken";
import { CustomiseState } from "./BrandConfigCustomise";

interface DropdownOption {
  value: string;
  label: string;
}

export const BrandConfigHome = () => {
  const { removeAccessToken } = useToken();
  const navigate = useNavigate();

  const [storesById, setStoresById] = useState<{
    [key: string]: Store;
  }>({});
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);

  const [sojoDomain, setSojoDomain] = useState<string>();
  const [storeUrl, setStoreUrl] = useState<string>();
  const [logoFile, setLogoFile] = useState<File>();
  const [logoFileB64, setLogoFileB64] = useState<any>();
  const [landingLogoFile, setLandingLogoFile] = useState<File>();
  const [landingLogoFileB64, setLandingLogoFileB64] = useState<any>();
  const [emailHeaderFile, setEmailHeaderFile] = useState<File>();
  const [emailHeaderFileB64, setEmailHeaderFileB64] = useState<any>();

  const uploadLogo = (file: File) => {
    setLogoFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result && typeof e.target?.result === "string") {
        setLogoFileB64(e.target?.result.split(",")[1]);
      }
    }
    reader.readAsDataURL(file);
  }

  const uploadLanding = (file: File) => {
    setLandingLogoFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result && typeof e.target?.result === "string") {
        setLandingLogoFileB64(e.target?.result.split(",")[1]);
      }
    }
    reader.readAsDataURL(file);
  }

  const uploadEmailHeader = (file: File) => {
    setEmailHeaderFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result && typeof e.target?.result === "string") {
        setEmailHeaderFileB64(e.target?.result.split(",")[1]);
      }
    }
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    AdminService.allStoresAdminStoreAllStoresGet()
      .then((result) => {
        setStoresById(
          result.reduce((groups: any, store) => {
            const group = groups[store._id] || [];
            group.push(store);
            groups[store._id] = group[0];
            return groups;
          }, {})
        );

        setDropdownOptions(
          result.map((store) => {
            return {
              value: store._id,
              label: store.store_name,
            } as DropdownOption;
          })
        );
      })
      .catch((err) => {
        if (err.status === 401) {
          removeAccessToken();
          window.location.reload();
        } else {
          alert(err.statusText);
        }
      });
  }, []);

  const enableWebhook = () => {
    if (selectedStore) {
      AdminService.enableWebhookAdminStoreEnableWebhookPost(selectedStore?._id).then((result) => {
        alert("Webhook enabled for store");
        window.location.reload();
      }).catch((err) => {
        console.error(err);
        alert("Failed to enable webhook for store")
      });
    }
  }

  const submitForm = async () => {
    if (
      storeUrl === undefined ||
      sojoDomain === undefined
    ) {
      alert("Store URL and SOJO domain must be provided");
    } else {
      let updatedStore = selectedStore;

      if (updatedStore === null) {
        return;
      }

      if (sojoDomain !== undefined) {
        updatedStore.sojo_domain = sojoDomain;
      } else {
        alert("Could not find sojo domain");
        return;
      }

      if (storeUrl !== undefined) {
        if (updatedStore.store_details) {
          updatedStore.store_details.store_url = storeUrl;
        }
      } else {
        alert("Could not find store url");
        return;
      }

      AdminService.putStoreAdminStoreStorePut(updatedStore)
        .then((result) => {
          alert("Store details successfully updated");
        })
        .catch((err) => {
          if (err.status === 401) {
            removeAccessToken();
            window.location.reload();
          } else {
            console.error(err);
            alert(err.statusText);
          }
        });

      if (logoFile || landingLogoFile || emailHeaderFile) {
        AdminService.putStoreImagesAdminStoreStoreImagesPut({
          store_id: updatedStore._id,
          logo_image_filename: logoFile?.name,
          logo_image_b64: logoFileB64,
          landing_image_filename: landingLogoFile?.name,
          landing_image_b64: landingLogoFileB64,
          email_header_image_filename: emailHeaderFile?.name,
          email_header_image_b64: emailHeaderFileB64
        })
          .then((result) => {
            alert("Store images successfully updated");
          }).catch((err) => {
            console.error(err);
            alert(err.statusText);
          });
      }
    }
  };

  const onSelectChange = (option: SingleValue<DropdownOption>) => {
    if (option !== null) {
      setSelectedOption(option);
      setSelectedStore(storesById[option.value]);
      setStoreUrl(storesById[option.value].store_details?.store_url);
      setSojoDomain(storesById[option.value].sojo_domain);
    }
  };

  const nextScreen = () => {
    if (selectedStore && selectedStore.sojo_domain) {
      const nextState: CustomiseState = {
        sojoDomain: selectedStore.sojo_domain,
        storeId: selectedStore._id
      };
      navigate(BrandConfigPath.BRAND_CONFIG_CUSTOMISE, {
        state: nextState,
      });
    } else {
      console.log("No store selected");
    }
  };


  return (
    <div className="root">
      <h1>Brand configuration service</h1>
      <Grid container>
        <Grid item xs={12}>
          <p>Select a store to configure</p>
          <Select
            value={selectedOption}
            options={dropdownOptions}
            onChange={onSelectChange}
          />
        </Grid>
      </Grid>

      {selectedStore !== null ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <h3>Selected store: {selectedStore.store_name}</h3>
            </Grid >
          </Grid >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h3>1. Customise item services</h3>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={nextScreen}>
                Customise Items
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h3>2. Update sojo domain and shopify url</h3>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="sojo-domain"
                label="Sojo Domain"
                variant="outlined"
                defaultValue={
                  selectedStore.sojo_domain ? selectedStore.sojo_domain : ""
                }
                onChange={(e) => setSojoDomain(e.target.value)}
                placeholder="xxx.sojo.uk"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="store-url"
                label="Store Url"
                variant="outlined"
                onChange={(e) => setStoreUrl(e.target.value)}
                defaultValue={
                  selectedStore.store_details?.store_url ? selectedStore.store_details?.store_url : ""
                }
                placeholder="xxx.myshopify.com"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <h3>3. Configure brand assets</h3>
            </Grid>
            <Grid item xs={12}>
              <p>Brand logo (max 8mb)</p>
            </Grid>
            <Grid item xs={8}>
              <FileUploader
                handleChange={uploadLogo}
                name="logo-file"
                types={["jpg", "jpeg", "png", "gif", "svg"]}
                maxSize={8}
                onSizeError={() => {
                  alert("File too large");
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {logoFile ? (
                <img
                  className="preview"
                  src={URL.createObjectURL(logoFile)}
                ></img>
              ) : (
                <>
                  {selectedStore.store_details?.logo_url !== null ? (
                    <img className="preview" src={selectedStore.store_details?.logo_url}></img>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <p>Brand logo for landing page (max 8mb)</p>
            </Grid>
            <Grid item xs={8}>
              <FileUploader
                handleChange={uploadLanding}
                name="logo-file"
                types={["jpg", "jpeg", "png", "gif", "svg"]}
                maxSize={8}
                onSizeError={() => {
                  alert("File too large");
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {landingLogoFile ? (
                <img
                  className="preview"
                  src={URL.createObjectURL(landingLogoFile)}
                ></img>
              ) : (
                <>
                  {selectedStore.store_details?.logo_url !== null ? (
                    <img className="preview" src={selectedStore.store_details?.landing_logo_url}></img>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <p>Email header image file (max 8mb)</p>
            </Grid>
            <Grid item xs={8}>
              <FileUploader
                handleChange={uploadEmailHeader}
                name="email-header-file"
                types={["jpg", "jpeg", "png", "gif", "svg"]}
                maxSize={8}
                onSizeError={() => {
                  alert("File too large");
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {emailHeaderFile ? (
                <img
                  className="preview"
                  src={URL.createObjectURL(emailHeaderFile)}
                ></img>
              ) : (
                <>
                  {selectedStore.store_details?.email_header_img_url !== null ? (
                    <img className="preview" src={selectedStore.store_details?.email_header_img_url}></img>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <h3>4. Webhook setup</h3>
              {selectedStore.webhook ? <>
                <p>Webhook is already enabled for this store</p>
                <p style={{ fontSize: "14px", marginBottom: 0 }}>Application ID: <code>{selectedStore.webhook.svix_app_id}</code></p>
                <p style={{ fontSize: "14px", marginTop: 0 }}>Zapier integration key: <code>{selectedStore.webhook.zapier_integration_key || "NONE"}</code></p>
              </> : <></>}
              <Button variant="contained" onClick={enableWebhook} color="error" disabled={selectedStore.webhook?.svix_app_id !== undefined}>
                Enable webhook
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center" marginTop={"20px"}>
            <Grid item xs={12}>
              <Button variant="contained" onClick={submitForm}>
                Update brand
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )
      }
    </div >
  );
};
