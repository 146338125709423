/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining pricing structure type
 */
export enum PricingStructureType {
    FLAT = 'flat',
    FIXED = 'fixed',
    PERCENTAGE = 'percentage',
}
