/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FittingChoice } from './FittingChoice';

/**
 * Core details for all alterations
 */
export type AlterationDetails = {
    service_type?: AlterationDetails.service_type;
    matched_item_description?: string;
    fitting_choice?: FittingChoice;
    note_to_seamster?: string;
    measurement_notes?: string;
};

export namespace AlterationDetails {

    export enum service_type {
        ALTERATION = 'alteration',
    }


}
