import { Grid } from "@mui/material"
import { AppPath } from "./App"

export const Home = () => {
    return (
        <div className="App landing">
            <Grid container>
                <Grid item xs={12}>
                    <h1>Welcome to Gatehouse</h1>
                    <h2>What would you like to do?</h2>
                </Grid >
            </Grid >
            <Grid container className="tile-container">
                {Object.entries(AppPath).map((path) => {
                    if (path[1] === AppPath.HOME) {
                        return <></>;
                    }
                    return (
                        <Grid item xs={6} justifyContent="center">
                            <div style={{display: "inline-block"}}>
                                <a href={path[1]}>
                                    <div className="tile-link">{path[0].split("_").join(" ").toLowerCase()}</div>
                                </a>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        </div >
    )
}