/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining order status
 */
export enum OrderStatus {
    AWAITING_PAYMENT = 'awaiting_payment',
    AWAITING_PICKUP = 'awaiting_pickup',
    AWAITING_STORE_DROPOFF = 'awaiting_store_dropoff',
    CUSTOMER_PICKUP_FAILED = 'customer_pickup_failed',
    IN_TRANSIT_TO_SOJO = 'in_transit_to_sojo',
    DELIVERY_TO_SOJO_FAILED = 'delivery_to_sojo_failed',
    ARRIVED_AT_SOJO = 'arrived_at_sojo',
    IN_PROGRESS = 'in_progress',
    AWAITING_DELIVERY = 'awaiting_delivery',
    IN_TRANSIT_TO_CUSTOMER = 'in_transit_to_customer',
    CUSTOMER_DELIVERY_FAILED = 'customer_delivery_failed',
    AWAITING_STORE_COLLECTION = 'awaiting_store_collection',
    FINISHED = 'finished',
    CANCELLED = 'cancelled',
    ATTENTION_REQUIRED = 'attention_required',
}
