/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible shift types
 */
export enum ShiftType {
    PICKUP = 'pickup',
    DROPOFF = 'dropoff',
    MIXED = 'mixed',
}
