import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import { BrandConfigHome } from "./Atoms/BrandConfig/BrandConfigHome";
import { BrandConfigCustomise } from "./Atoms/BrandConfig/BrandConfigCustomise";
import { BrandConfigPath } from "./Atoms/BrandConfig/BrandConfigPath";
import { Home } from "./Home";
import { OpenAPI } from "./sojoApi";
import { BrandConfigOutlet } from "./Atoms/BrandConfig/Outlet/BrandConfigOutlet";

export const AppPath = {
  HOME: "/",
  BRAND_CONFIG: "brand-config",
};

const API_ENDPOINT =
  process.env.REACT_APP_SOJO_API_ENDPOINT || "http://localhost:8000";

function App() {
  OpenAPI.BASE = API_ENDPOINT;

  return (
    <Router>
      <Routes>
        <Route path={AppPath.HOME} element={<Home />} />
        <Route path={AppPath.BRAND_CONFIG} element={<BrandConfigOutlet />}>
          <Route
            path={BrandConfigPath.BRAND_CONFIG_HOME}
            element={<BrandConfigHome />}
          />
          <Route
            path={BrandConfigPath.BRAND_CONFIG_CUSTOMISE}
            element={<BrandConfigCustomise />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
