/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining service type
 */
export enum ServiceType {
    ALTERATION = 'alteration',
    REPAIR = 'repair',
    UPSIZING = 'upsizing',
}
