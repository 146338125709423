/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Button type for a item level question
 */
export enum QuestionType {
    SINGLE_SELECT = 'single_select',
    MULTI_SELECT = 'multi_select',
}
