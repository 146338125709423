/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining order priority levels
 */
export enum Priority {
    LOW = 'low',
    STANDARD = 'standard',
    HIGH = 'high',
    VIP = 'vip',
    BRAND = 'brand',
}
