import "./BrandConfig.css";
import {
  AdminService,
  PublicService,
  StoreItemCustomisations,
  AlterationName,
  Item,
  Store,
  RepairName,
} from "../../sojoApi";

import { ChangeEvent, Key, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React from "react";

export interface CustomiseState {
  sojoDomain: string;
  storeId: string;
}

interface ItemState {
  item_id: string;
  repair_ids: string[];
  alteration_ids: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const BrandConfigCustomise = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomiseState;

  const [customSwitch, setCustomSwitch] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [itemCustomisations, setItemCustomisations] = useState<
    StoreItemCustomisations[]
  >([]);
  const [updatedItems, setUpdatedItems] = useState<ItemState[]>([]);

  useEffect(() => {
    AdminService.storeAdminStoreStoreGet(
      state.storeId
    ).then((store: Store) => {
      if (store.store_item_customisations) {
        setItemCustomisations(store.store_item_customisations);
      }
    })
      .catch((error: any) => {
        console.log(error);
      });
  }, [])

  const getAlterationName = (alteration_id: string) => {
    let alteration_name = "";
    items.forEach((item) => {
      if (item.alterations) {
        item.alterations.forEach((alteration) => {
          if (alteration._id === alteration_id) {
            alteration_name = alteration.name;
          }
        });
      } else {
        return "Alteration not found";
      }
    });
    return alteration_name;
  };

  const getRepairName = (repair_id: string) => {
    let repair_name = "";
    items.forEach((item) => {
      if (item.repairs) {
        item.repairs.forEach((repair) => {
          if (repair._id === repair_id) {
            repair_name = repair.name;
          }
        });
      } else {
        return "Alteration not found";
      }
    });
    return repair_name;
  };

  const getItems = () => {
    // Get items to populate the drop downs with all services.
    AdminService.allItemsAdminAllItemsGet()
      .then((result: Item[]) => {
        setItems(result);
        const initialState = result.map(
          (item) =>
          ({
            item_id: item._id,
            alteration_ids: [],
            repair_ids: [],
          } as ItemState)
        );
        if (itemCustomisations) {
          itemCustomisations.forEach(item_customisation => {
            initialState.forEach(updatedItem => {
              if (updatedItem.item_id === item_customisation.item_id) {
                updatedItem.alteration_ids = item_customisation.alterations?.map(alteration => alteration._id) || [];
                updatedItem.repair_ids = item_customisation.repairs?.map(repair => repair._id) || [];
              }
            });
          });
        }
        setUpdatedItems(initialState);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleSwitchChange = () => {
    try {
      getItems();
    } catch (error) {
      console.log(error);
    }
    setCustomSwitch(!customSwitch);
  };

  const handleAlterationChange = (
    event: SelectChangeEvent<typeof alterations>,
    item_id: string
  ) => {
    const {
      target: { value },
    } = event;
    // Splits string of of item ids into an array.
    const alterations: string[] =
      typeof value === "string" ? value.split(",") : value;

    // Update the selected alterations for the item.
    const newUpdatedItems = updatedItems.map((updatedItem) =>
      updatedItem.item_id === item_id
        ? { ...updatedItem, alteration_ids: alterations }
        : updatedItem
    );
    setUpdatedItems(newUpdatedItems);
  };

  const handleRepairChange = (
    event: SelectChangeEvent<typeof repairs>,
    item_id: string
  ) => {
    const {
      target: { value },
    } = event;
    // Splits string of of item ids into an array.
    const repairs: string[] =
      typeof value === "string" ? value.split(",") : value;

    // Update the selected alterations for the item.
    const newUpdatedItems = updatedItems.map((updatedItem) =>
      updatedItem.item_id === item_id
        ? { ...updatedItem, repair_ids: repairs }
        : updatedItem
    );
    setUpdatedItems(newUpdatedItems);
  };

  const handleSubmit = () => {
    if (!customSwitch) {
      // If customisation is turned off, add all customisations to the store.
      AdminService.addAllStoreItemCustomisationsAdminStoreAddAllStoreItemCustomisationsPost(
        state.sojoDomain
      )
        .then((result) => {
          alert("Store successfully updated");
          // Navigate to the previous configuration page.
          navigate(-1);
        })
        .catch((err) => {
          alert(err.statusText);
        });
    } else {
      // If customisation is turned on, add the selected customisations to the store.
      const updatedCustomisations: StoreItemCustomisations[] = updatedItems
        .map((updatedItem) => {
          // For each item, retrieve the selected alteration ids and names.
          const updatedAlterations: AlterationName[] =
            updatedItem.alteration_ids.map(
              (alteration_id) =>
              ({
                _id: alteration_id,
                name: items
                  .map((item) => item.alterations)
                  .flat()
                  .find((alteration) => alteration._id === alteration_id)
                  ?.name,
              } as AlterationName)
            );

          const updatedRepairs: RepairName[] =
            updatedItem.repair_ids.map(
              (repair_id) =>
              ({
                _id: repair_id,
                name: items
                  .map((item) => item.repairs)
                  .flat()
                  .find((repair) => repair._id === repair_id)
                  ?.name,
              } as RepairName)
            );

          // Populate the customisations object with the selected alterations and repairs.
          return {
            item_id: updatedItem.item_id,
            name: items.find((item) => item._id === updatedItem.item_id)?.name,
            repairs: updatedRepairs,
            alterations: updatedAlterations,
          } as StoreItemCustomisations;
        })
        // Filter out items that have no alterations or repairs selected.
        .filter(
          (item) =>
            (item.repairs && item.repairs.length > 0) || (item.alterations && item.alterations.length > 0)
        );

      // Update store item customisations for store in the database.
      AdminService.postStoreItemCustomisationsAdminStoreStoreItemCustomisationsPost(
        state.sojoDomain,
        updatedCustomisations
      )
        .then((result) => {
          alert("Store successfully updated");
          // Navigate to the previous configuration page.
          navigate(-1);
        })
        .catch((err) => {
          alert(err.statusText);
        });
    }
  };

  return (
    <div className="root">
      <Grid container>
        <Grid item xs={12}>
          <h1>Item Customisation</h1>
          <p>
            Please select all the services this store will require on each item.
            If no services are selected for an item, Sojo will not be available
            for that item on the store.
          </p>
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              label="Store requires item customisation"
              onChange={handleSwitchChange}
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container>
        {customSwitch ? (
          <>
            {items.map((item: Item, i: Key) => (
              <React.Fragment key={i}>
                <Grid item xs={12}>
                  <h2>{item.name}</h2>
                </Grid>
                <Grid item xs={12}>
                  <h4>Edit services: </h4>
                  {item.alterations ? (
                    <FormControl sx={{ m: 1, width: 300 }} key={item._id}>
                      <InputLabel id="alterations-label">
                        Alterations
                      </InputLabel>
                      <Select
                        labelId="alterations-label"
                        id="alterations"
                        multiple
                        value={
                          updatedItems.find(
                            (updatedItem) => updatedItem.item_id === item._id
                          )?.alteration_ids || []
                        }
                        onChange={(e) => {
                          handleAlterationChange(e, item._id);
                        }}
                        input={
                          <OutlinedInput
                            id="alterations"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={getAlterationName(value)}
                                />
                              ))}
                            </Box>
                          );
                        }}
                        MenuProps={MenuProps}
                      >
                        {item.alterations.map((alteration) => (
                          <MenuItem key={alteration._id} value={alteration._id}>
                            {alteration.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <p>This item does not have any alterations.</p>
                  )}
                  {item.repairs ? (
                    <FormControl sx={{ m: 1, width: 300 }} key={item._id}>
                      <InputLabel id="repairs-label">
                        Repairs
                      </InputLabel>
                      <Select
                        labelId="repairs-label"
                        id="repairs"
                        multiple
                        value={
                          updatedItems.find(
                            (updatedItem) => updatedItem.item_id === item._id
                          )?.repair_ids || []
                        }
                        onChange={(e) => {
                          handleRepairChange(e, item._id);
                        }}
                        input={
                          <OutlinedInput
                            id="repairs"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={getRepairName(value)}
                                />
                              ))}
                            </Box>
                          );
                        }}
                        MenuProps={MenuProps}
                      >
                        {item.repairs.map((repair) => (
                          <MenuItem key={repair._id} value={repair._id}>
                            {repair.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <p>This item does not have any alterations.</p>
                  )}
                </Grid>
              </React.Fragment>
            ))}
          </>
        ) : (
          <p>
            Pressing save and return button will add all items and services to
            this store.
          </p>
        )}
      </Grid>
      <Grid>
        <Button variant="contained" onClick={handleSubmit}>
          Save and Return
        </Button>
      </Grid>
    </div>
  );
};
