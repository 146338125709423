/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UpsizingUnits {
    CENTIMETERS = 'centimeters',
    INCHES = 'inches',
}
