import { useState } from 'react';
import { AuthenticationToken } from '../../../sojoApi';

export default function useToken() {
  const getAccessToken = () => {
    const tokenString = sessionStorage.getItem('brand_config_token');
    if (tokenString === null) {
        return undefined;
    }
    const userToken: AuthenticationToken = JSON.parse(tokenString);
    return userToken.access_token
  };

  const [accessToken, setAccessToken] = useState(getAccessToken());

  const saveAccessToken = (userToken: AuthenticationToken) => {
    sessionStorage.setItem('brand_config_token', JSON.stringify(userToken));
    setAccessToken(userToken.access_token);
  };

  const deleteAccessToken = () => {
    sessionStorage.clear();
    setAccessToken(undefined);
  };

  return {
    setAccessToken: saveAccessToken,
    removeAccessToken: deleteAccessToken,
    accessToken
  }
}
