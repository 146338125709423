/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum StoreInStoreType {
    COMPLETE_IN_STORE = 'complete_in_store',
    RETURN_TO_STORE = 'return_to_store',
    RETURN_TO_CUSTOMER = 'return_to_customer',
}
