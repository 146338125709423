/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining service tier codes
 */
export enum Tier {
    TIER1 = 'tier1',
    TIER2 = 'tier2',
    TIER3 = 'tier3',
}
